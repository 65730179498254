import notfound from '../assets/images/notfound.png';
import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import Ariane from './Ariane';
import BottomNavigation from './BottomNavigation'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCreative } from 'swiper';
import { useCart } from "react-use-cart";
import { MiniCart, QuantitySelector, formatCurrency, isDiscounted, getDiscountPrice } from './Cart';
import DataTable, { createTheme } from 'react-data-table-component';
import bs5Styles from '../assets/themes/bs5';
import Select from "react-select";
import { useCustomFields } from '../forms/CustomFields';
import { useIndexedDB } from 'react-indexed-db';
import { strings } from '../localization';
import { set } from 'date-fns';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "./styles.css";

const filterCategories = (foundCategories, search) => {
    return foundCategories.filter( (category) => (
        ( ! search?.category ||
            category.ref == search.category.value ||
            (category.parent != null && category.parent.ref == search.category.value) ||
            (category.children.length > 0 && category.children.find((cc, idx) => {
                return (
                    cc.ref == search.category.value ||
                    (cc.children.length > 0 && cc.children.find(ccc => ccc.ref == search.category.value) != undefined)
                );
            }))
        )
    ))
}

function Catalog( props ){

    //const categories = JSON.parse(localStorage.getItem('categories'))
    //const products = JSON.parse(localStorage.getItem('products'))

    const { getAll } = useIndexedDB('catalog');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [ categorySearch, setCategorySearch ] = useState('')
   
    sessionStorage.setItem('browse_mode', 'catalog')
    useEffect(() => {
        getAll().then(dataFromDB => {
            setProducts(dataFromDB.find(i => i.type == 'products')?.data ?? [])
            setCategories(dataFromDB.find(i => i.type == 'categories')?.data ?? [])
        });
        
        setCategorySearch(sessionStorage.getItem('catalog.search_category') ? JSON.parse(sessionStorage.getItem('catalog.search_category')) : '');
    }, []);

    let params = useParams()
    let url_params = params['*'].split('/')

    let categoryRef = url_params.length ? url_params[url_params.length-1] : null
    
    const [page, setPage ] = useState(0)    
   
    const navigate = useNavigate()

    const recursiveFindCategory = (cats, ref) => {        

        for(let i = 0; i< cats.length; i++){
            
            if(cats[i].ref == ref)
            return cats[i]            
            
            const found = cats[i].children ? recursiveFindCategory(cats[i].children,ref) : false;
            
            if(found)
            return found;            
            
        };

        return false;
    }

    const recursiveFindProductCategory = (cats, ref) => {
        if (cats.ref == ref)
            return cats;

        const found = cats.parent ? recursiveFindProductCategory(cats.parent, ref) : false;
        return found;
    }

    const [ currentCategory, _setCurrentCategory ] = useState( categoryRef ? recursiveFindCategory(categories,categoryRef) : null)    

    const setCurrentCategory = (value) => {

        _setCurrentCategory(value);
        setPage(0)

    }

    useEffect(() => {
        
        if(categoryRef && (!currentCategory ||  categoryRef != currentCategory.ref))
        setCurrentCategory(recursiveFindCategory(categories,categoryRef))
        else
        if(!categoryRef && currentCategory)
        setCurrentCategory(null)

        
       


        
    })

    useEffect(() => {
        if (
            sessionStorage.getItem('backClicked') === 'true' && 
            sessionStorage.getItem('catalog.current_selected_page') !== page
        ) {
            setPage(parseInt(sessionStorage.getItem('catalog.current_selected_page')));
            setTimeout(() => {
                sessionStorage.setItem('backClicked', false);
            }, 300);
        }
    })

    let dsCategoryRef = (currentCategory && currentCategory.ref.includes('ds-')) ? currentCategory.ref.replace('ds-', '') : '';
    let foundCategories = currentCategory ? currentCategory.children : categories
    let foundProducts = currentCategory ? products.filter((p) => (
        p.categories.ref == currentCategory.ref ||
        (currentCategory.ref.includes('ds-') && recursiveFindProductCategory(p.categories, dsCategoryRef) && p.destock == true)
    ) ) : products

    const displayMode = foundCategories && foundCategories.length  ? "categories" : "products"
    let totalResult = 0
    const [ pagination, setPagination ] =  useState(12)

    if(displayMode == "categories"){
        totalResult = foundCategories.length
    }
    else
    if(displayMode == "products"){
        totalResult = foundProducts.length
    }
    const totalPages = Math.ceil(totalResult / pagination)

    const gotoPreviousPage = () => {

        let previousPage = page-1

        if(previousPage >= 0)
        handleCatalogPageChange( previousPage )
    }

    const gotoNextPage = () => {

        let nextPage = page+1;

        if(nextPage <  totalPages)
        handleCatalogPageChange( nextPage )
       

    }

    const parentCategory = currentCategory && currentCategory.parent ? recursiveFindCategory(categories, currentCategory.parent.ref) : null;
    const previousCategoryIndex = parentCategory ? parentCategory.children.findIndex(c => c.ref === currentCategory.ref)-1 : null;
    const previousCategory = previousCategoryIndex ? parentCategory.children[previousCategoryIndex] : null;
    const nextCategoryIndex = parentCategory ? parentCategory.children.findIndex(c => c.ref === currentCategory.ref)+1 : null;        
    const nextCategory = nextCategoryIndex && nextCategoryIndex <  parentCategory.children.length ? parentCategory.children[nextCategoryIndex] : null;

    const gotoPreviousCollection = (e) => {
        e.preventDefault();

        if(previousCategory)
        navigate('/catalog/'+previousCategory.ref, { replace: true });
    }

    const gotoNextCollection = (e) => {   
        e.preventDefault(); 

        if (nextCategory) {
            navigate('/catalog/'+nextCategory.ref, { replace: true });

            const swiper = document.querySelector('.swiper-catalog').swiper;
            swiper.activeIndex = 0;
        }
    }

    const pageItems = [
        displayMode === "products" && <li className='page-item' key='collection-backward'>
            <a href="#" onClick={ gotoPreviousCollection } className={'page-link' + ( !previousCategory ? ' opacity-50':'') }><i className='fa fa-step-backward'></i></a>
        </li>,
        <li className='page-item' key='page-backward'>
            <a href="#" onClick={ gotoPreviousPage } className={'page-link' + (page <= 0 ? ' opacity-50':'') }><i className='fa fa-backward'></i></a>
        </li>
    ]      
    
    for(let i = 0; i< totalPages; i++){
        pageItems.push(
        <li key={ 'page_item_' + i } className={ page == i ? 'page-item active' : 'page-item' }>
            <a href="#" onClick={ () => { handleCatalogPageChange(i); }  } className='page-link'>{ i+1 }</a>
        </li>)
    }    

    pageItems.push(
        <li className='page-item' key='page-forward'>
    <a href="#" onClick={ gotoNextPage } className={'page-link' + (page >= totalPages-1 ? ' opacity-50':'') }><i className='fa fa-forward'></i></a></li>,
    displayMode === "products" && <li className='page-item' key='collection-forward'>
        <a href="#" onClick={ gotoNextCollection } className={'page-link' + (! nextCategory ? ' opacity-50':'') }><i className='fa fa-step-forward'></i></a>
    </li>)

    const start = page*pagination
    const end = start + pagination
    let itemClassNames = 'col-lg-3';
    switch(pagination){

        case 6:
        itemClassNames = 'col-lg-4'
        break

        case 12:
        itemClassNames = 'col-lg-3'
        break



            
    }




    const getBreadcrumb = (cat) => {

        
        let cats = [cat]   
        
        let currentCat = cat

        while(currentCat.parent){

            cats.push(currentCat.parent)
            currentCat = currentCat.parent

        }

        

        cats = cats.reverse();

       

        let cats_link = cats.map((c, index) => 
            {
              
                return (
                    <Link className='text-white'  key={ index } to={ "/catalog/"+ c.ref } >{ c.title }</Link>
                )
            }            
        );

        let breadCrumb = <div className=''>
            <Link className='text-white'  key="root" to="/catalog" >{ strings.catalogue }</Link> &gt;
            { 
                
                cats_link.map( (link,index) => 
                    [
                        index > 0 ? " > " : '',
                        link
                    ]
                )
            }
        </div>
            
       

        return breadCrumb;
    }

    const handleChangePagination = (e) => {
        setPagination(parseInt(e.target.value));
    }

    const handleCatalogCategorySearch = (value) => {

        sessionStorage.setItem('catalog.search_category', JSON.stringify(value))  
        setCategorySearch(value)

    }

    const handleCatalogPageChange = (value) => {
        
        sessionStorage.setItem('catalog.current_selected_page', JSON.stringify(value))  
        setPage(value)
    }

    const [{ selectStyles }] = useCustomFields()
    
    const categoriesOptions =  []
    
    const recursiveGetCategory = (cats, level = -1) => {

        level++

        const spacer =  level  ? "---".repeat(level)+" " : ''

        cats.forEach(c => {
            categoriesOptions.push({ label:spacer+c.title, value:c.ref, parent:c.parent })
            if(c.children.length)
            recursiveGetCategory(c.children,level)
        }) 
        
    }

    if (categories.length === foundCategories.length) {
        recursiveGetCategory(categories);
    } else {
        recursiveGetCategory(foundCategories);
    }

    const filteredCategories = filterCategories(foundCategories,{'category':categorySearch })

    // Create array with slides
    const slides = Array.from({ length: totalPages }).map(
        (el, index) => `Slide ${index + 1}`
    );
   
    return (
        
        <div className="products h-100 d-flex flex-column justify-content-between">
            <Ariane showSearch>
                <h1 className='titre text-center flex-grow-1 fs-4'>{ currentCategory ? getBreadcrumb(currentCategory) : strings.catalogue_produit }</h1>
            </Ariane>
            { displayMode === "categories" 
                ?
                <div className='categories-list my-4 container-xxl'>
                    <div className='search-bar mb-3'>
                        <div className="row justify-content-end">
                            <div className='col-lg-3' style={ {zIndex:2 } }>
                                <Select styles={ selectStyles } onChange={ handleCatalogCategorySearch } value={ categorySearch } isClearable={ true } name="collection" className="custom-select" options={ categoriesOptions } />
                            </div>
                        </div>
                    </div>
                    <Swiper pagination={false} modules={[Pagination]} onSlideChange={(swiper) => handleCatalogPageChange(swiper.activeIndex)} className='swiper-catalog'>
                        {slides.map((slideContent, index) => (
                            <SwiperSlide key={slideContent}>
                                <div className='row'>
                                    { filteredCategories.slice(start,end).map( cat => (
                                        <div className={ itemClassNames+' mb-4' } key={ 'cat_'+cat.ref }>
                                            <Link to={ cat.ref } className='btn btn-primary btn-category p-0 w-100'>
                                                <div className='ratio ratio-16x9'>
                                                    { cat.image ? <img src={ cat.image } className="img img-cover" /> : ( products.find(p => p.categories.ref == cat.ref ) ? <img src={ products.find((p) => p.categories.ref == cat.ref )?.images[0] } className="img img-cover" /> : <img src={ notfound } className="img img-cover" /> ) }
                                                </div>                                
                                                <div className='caption'>
                                                    { cat.title}
                                                </div>
                                                { currentCategory && currentCategory.ref === 'destockage' && <span className="badge rounded-pill text-bg-warning position-absolute top-0 end-0  mt-1 me-1">{ strings.destock }</span>}  
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                : 
                <Swiper observer={true} pagination={false} modules={[Pagination]} onSlideChange={(swiper) => handleCatalogPageChange(swiper.activeIndex)} className='swiper-catalog'>
                    {slides.map((slideContent, index) => (
                        <SwiperSlide key={slideContent}>
                            <div className='products-list my-4 container-xxl p-0'>
                                <div className='row'>
                                    {
                                        foundProducts.slice(start,end).map( product => (
                                            <ProductItem product={ product } className={ itemClassNames } key={ product.ref } withStock={ false } futureStockValue={ 0 } />
                                        ))
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            }

            <BottomNavigation>
                <Link to="/" className='fs-1 text-white col-3 text-start'>
                    <i className='fa fa-home'></i>
                </Link>
                <div className="col-6 d-flex align-items-center justify-content-center">
                    <ul className='pagination justify-content-center m-0 pagination me-3'>
                        { pageItems }
                    </ul>
                    <div className="d-flex justify-content-center">
                        <div className="col-auto">
                            <label className="col-form-label">{ strings.afficher }</label>
                        </div>
                        <div className="col-auto">
                            <select name="product_num" value={ pagination } onChange={ handleChangePagination } className="ms-3 form-select">                        
                              
                                <option value="6">6 { strings.elements}</option>
                                <option value="12">12 { strings.elements}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-3'>
                    <MiniCart />
                </div>
            </BottomNavigation>
        </div>
    )
}

export default Catalog;

const filterProducts = (products, search) => {
    
    
   
    return products.filter( (product) => (
        ( ! search?.ref || 
            (
                product.ref.toLowerCase().includes(search.ref.toLowerCase()) 
                || 
                product.title.toLowerCase().includes(search.ref.toLowerCase())
            )
        )
        &&
        ( ! search?.destock || product.destock )
        &&
        ( ! search?.category ||
            product.categories.ref == search.category.value
        )
    ))
}


function ProductList(){

    //const products = JSON.parse(localStorage.getItem('products'))
    //const categories = JSON.parse(localStorage.getItem('categories'))
    sessionStorage.setItem('browse_mode', 'list')

    const { getAll } = useIndexedDB('catalog');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [ orders, setOrders] = useState([])
    const [ productSearch, setProductSearch ] = useState('')
    const [ destockSearch, setDestockSearch ] = useState(false)
    const [ categorySearch, setCategorySearch ] = useState('')
    const [page, setPage ] = useState(1)    
    const [ pagination, setPagination ] =  useState(12)
    let totalResult = 0

    useEffect(() => {
        getAll().then(dataFromDB => {
            setProducts(dataFromDB.find(i => i.type == 'products')?.data ?? [])
            setCategories(dataFromDB.find(i => i.type == 'categories')?.data ?? [])
            setOrders(dataFromDB.find(i => i.type === 'orders')?.data ?? [])
        });
        setProductSearch(sessionStorage.getItem('products.search_ref') ?? '');
        setDestockSearch(sessionStorage.getItem('products.search_destock') === 'true' ? true:false);
        
        setCategorySearch(sessionStorage.getItem('products.search_category') ? JSON.parse(sessionStorage.getItem('products.search_category')) : '');
        
        
        if (
            sessionStorage.getItem('backClicked') === 'true' && 
            sessionStorage.getItem('products.current_selected_page') &&
            sessionStorage.getItem('products.current_selected_page') !== page
        ) {
            setPage(parseInt(sessionStorage.getItem('products.current_selected_page')));
            setTimeout(() => {
                sessionStorage.setItem('backClicked', false);
            }, 300);
        }
    }, []);

    const { addItem, inCart, getItem, updateItemQuantity, removeItem } = useCart()

    const getProductPackUnit = (id, defaultVal) => {
        return sessionStorage.getItem('products.productPackUnit.' + id) ? parseInt(sessionStorage.getItem('products.productPackUnit.' + id)) : defaultVal;
    }

    const handleAddToCart = (product) => {        
        inCart(product.ref) ? updateItemQuantity(getItem(product.ref).id, getItem(product.ref).quantity + getProductPackUnit(product.ref, product.pack_unit)) :  addItem({ id:product.ref, price: product.regular_price, name: product.title,  image: product.images.length ? product.images : null, pack_unit : getProductPackUnit(product.ref, product.pack_unit), discount: null, regular_price: product.regular_price}, getProductPackUnit(product.ref, product.pack_unit))
    }
    
    const paginationComponentOptions = {
        rowsPerPageText: strings.articles_page,
        rangeSeparatorText: strings.articles_of,
        selectAllRowsItem: true,
        selectAllRowsItemText: strings.articles_all,
        
    };
    let navigate = useNavigate();
    const viewProduct = (ref) => {
        navigate('/product/'+ref)
    }

  

    const handleProductSearch = (e) => {

        sessionStorage.setItem('products.search_ref',e.target.value)

        setProductSearch(e.target.value)
        handleProductsPageChange(1)

    }

    const handleDestockSearch = (e) => {

        sessionStorage.setItem('products.search_destock',!destockSearch)

        setDestockSearch(!destockSearch)
        handleProductsPageChange(1)

    }

    const handleCategorySearch = (value) => {

        sessionStorage.setItem('products.search_category', JSON.stringify(value))  
        setCategorySearch(value)
        handleProductsPageChange(1)

    }

    const filteredProducts = filterProducts(products,{ 'ref': productSearch, 'category':categorySearch, 'destock': destockSearch })

    const [{ selectStyles }] = useCustomFields()
    
    const categoriesOptions =  []
    
    const recursiveGetCategory = (cats, level = -1) => {

        level++

        const spacer =  level  ? "---".repeat(level)+" " : ''

        cats.forEach(c => {
            categoriesOptions.push({ label:spacer+c.title, value:c.ref })
            if(c.children.length)
            recursiveGetCategory(c.children,level)
        }) 
        
    }

    recursiveGetCategory(categories)

    totalResult = filteredProducts.length
    const totalPages = Math.ceil(totalResult / pagination)

    const gotoPreviousPage = () => {

        let previousPage = page-1
        
        if(previousPage >= 1)
        handleProductsPageChange( previousPage )
    }

    const gotoNextPage = () => {

        let nextPage = page+1;
        
        if(nextPage <=  totalPages)
        handleProductsPageChange( nextPage )
       

    }

    const pageItems = [
        <li className='page-item' key='page-backward'>
            <a href="#" onClick={ gotoPreviousPage } className={'page-link' + (page <= 1 ? ' opacity-50':'') }><i className='fa fa-backward'></i></a>
        </li>
    ];

    const getPaginationButtons = () => {
        if (totalPages <= 4) {
            const pages = [...new Array(totalPages)].map((_, index) =>
                (index + 1).toString()
            );
            return pages;
        }

        const pages = ['1'];

        if (page >= 4) {
            pages.push('...');
        }

        if (page + 1 > totalPages) {
            pages.push((page - 2).toString());
        }

        if (page - 1 > 1) {
            pages.push((page - 1).toString());
        }

        if (page !== 1) {
            pages.push(page.toString());
        }

        if (page + 1 <= totalPages) {
            pages.push((page + 1).toString());
        }

        if (page - 1 <= 0) {
            pages.push((page + 2).toString());
        }

        if (page + 2 < totalPages) {
            pages.push('...');
        }

        if (page + 1 < totalPages) {
            pages.push(totalPages.toString());
        }

        return pages;
    };

    const paginationButtons = getPaginationButtons();
    paginationButtons.forEach((val, idx) => {
        if (val === '...') {
            pageItems.push(
            <li key={ 'page_item_dd' + idx } className={ 'page-item' }>
                <a href="#" className='page-link'>...</a>
            </li>);
        } else {
            let i = parseInt(val);
            pageItems.push(
            <li key={ 'page_item_' + i } className={ page == i ? 'page-item active' : 'page-item' }>
                <a href="#" onClick={ () => { handleProductsPageChange(i);} } className='page-link'>{ i }</a>
            </li>);
        }
    });

    pageItems.push(
        <li className='page-item' key='page-forward'>
        <a href="#" onClick={ gotoNextPage } className={'page-link' + (page >= totalPages ? ' opacity-50':'') }><i className='fa fa-forward'></i></a></li>
    );

    const start = (page - 1)*pagination
    const end = start + pagination
    let itemClassNames = 'col-lg-3';
    switch(pagination){
        case 6:
            itemClassNames = 'col-lg-4'
            break
        case 12:
        default:
            itemClassNames = 'col-lg-3'
            break
    }
    const handleChangePagination = (e) => {
        setPagination(parseInt(e.target.value));
        handleProductsPageChange(1)
    }

    const handleProductsPageChange = (value) => {
        
        sessionStorage.setItem('products.current_selected_page', JSON.stringify(value));
        setPage(value);
    }

    const recursiveFindProductQty = (ref, status) => {
        let count = 0;
        let filteredOrders = orders.filter(o => o.status == status);
        if (status === 2) {
            filteredOrders = filteredOrders.filter(o => new Date(o.shipping_date * 1000) > new Date());
        }
        for (const key in filteredOrders) {
            if (Object.prototype.hasOwnProperty.call(filteredOrders, key)) {
                const order = filteredOrders[key];
                for (const k in order.items) {
                    if (Object.prototype.hasOwnProperty.call(order.items, k)) {
                        const orderItem = order.items[k];
                        if (orderItem.ref === ref) {
                            count += orderItem.quantity;
                        }
                    }
                }
            }
        }
        return count;
    }
    
    const handleFutureStocks = (ref, defaultStockValue) => {
        if (ref) {
            let productQtyFromOrdersConfirmed = recursiveFindProductQty(ref, 1);
            let productQtyFromOrdersSent = recursiveFindProductQty(ref, 2);

            let futureStock = defaultStockValue - (productQtyFromOrdersConfirmed + productQtyFromOrdersSent);
            return futureStock > 0 ? futureStock : 0;
        }
        return defaultStockValue;
    }

    return ( 

        <div className="products h-100 d-flex flex-column justify-content-between">
            <Ariane showSearch>
                <h1 className='titre text-center flex-grow-1'>{ strings.liste_articles}</h1>
            </Ariane>            
            <div className='products-list my-4 container-xxl'>
                <div className='search-bar mb-3'>
                    <div className="row justify-content-end">
                        <div className='col-lg-2'>
                            <input type="text" name="ref" className="form-control" onChange={ handleProductSearch } value={ productSearch } placeholder='REF' />                        
                        </div>
                        <div className='col-lg-2'>
                            <div className='form-check text-start'>
                                <input type="checkbox" name="destock" id="destock" className="form-check-input" onChange={ handleDestockSearch } checked={ destockSearch }  /> 
                                <label className='form-check-label' htmlFor='destock'>
                                    { strings.destock }
                                </label>
                            </div>             
                        </div>
                        <div className='col-lg-3' style={ {zIndex:2}}>
                            <Select styles={ selectStyles } onChange={ handleCategorySearch } value={ categorySearch } isClearable={ true } name="collection" className="custom-select" options={ categoriesOptions } />
                        </div>
                        <div className='col-lg-2'>
                            <button type='submit' className='btn btn-primary w-100 text-white'>
                                { strings.rechercher}
                            </button>                     
                        </div>
                    </div>    
                </div>   
                
                <div className='products-list my-4 container-xxl p-0'>
                    <div className='row'>
                        {
                            filteredProducts.slice(start,end).map( product => (
                                <ProductItem product={ product } className={ itemClassNames } key={ product.ref } withStock={ true } futureStockValue={ handleFutureStocks(product.ref, product.stock) } />
                            ))
                        }
                    </div>
                </div>
            </div>
            <BottomNavigation>
                <Link to="/" className='fs-1 text-white col-2 text-start'>
                    <i className='fa fa-home'></i>
                </Link>
                <div className="col-8 d-flex align-items-center justify-content-center" style={ {zIndex:2}}>
                    <ul className='pagination justify-content-center m-0 pagination me-3'>
                        { pageItems }
                    </ul>
                    <div className="d-flex justify-content-center">
                        <div className="col-auto">
                            <label className="col-form-label">{ strings.afficher }</label>
                        </div>
                        <div className="col-auto">
                            <select name="product_num" value={ pagination } onChange={ handleChangePagination } className="ms-3 form-select">                        
                              
                                <option value="6">6 { strings.elements}</option>
                                <option value="12">12 { strings.elements}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-2'>
                    <MiniCart />
                </div>
            </BottomNavigation>
        </div> 
    )
}

function ProductItem(props){

    const { product, withStock, futureStockValue, ...rest } = props
    const { addItem, inCart, getItem, updateItemQuantity, removeItem } = useCart()
   
   
    const [ isInCart, setIsInCart ] = useState(inCart(product.ref)); 

    const [toggled, setToggled] = useState(false)
   
    const getProductPackUnit = (id, defaultVal) => {
        return sessionStorage.getItem('products.productPackUnit.' + id) ? parseInt(sessionStorage.getItem('products.productPackUnit.' + id)) : defaultVal;
    }

    const handleAddItem = () => {

        addItem({ id:product.ref, regular_price: product.regular_price, price: product.regular_price, discount: null, name: product.title, image: product.images.length ? product.images : null, pack_unit : getProductPackUnit(product.ref, product.pack_unit) }, getProductPackUnit(product.ref, product.pack_unit))
        setIsInCart(true)
       setToggled(true)
        
    }
   
    const handleUpdateQuantity = (newQty) => {
        if(inCart(product.ref)){
            
            if(newQty){
                updateItemQuantity(item.id, newQty);
                setIsInCart(true)
            }
            else{
                removeItem(item.id);
                setIsInCart(false)
                setToggled(false)
            }
                
        }
        
    }
    
    const item = getItem(product.ref)

    return (
        <div {...rest} >
            <div className='item'>
                <div className='position-relative'>
                    <Link to={ "/product/" + product.ref } className='ratio bg-white ratio-16x9'>
                        { product.images.length > 0
                            ? 
                            <img src={ product.images } className="img img-contain" />
                            :
                            <img src={ notfound } className="img img-contain" />
                        } 
                                         
                    </Link>                
                    { ! isInCart 
                    ? 
                        <button type="button" onClick={ handleAddItem } className='btn btn-primary add-to-cart text-white' style={ {zIndex:0 } }>
                            <i className='fa fa-shopping-bag'></i>
                        </button>
                    :
                        <QuantitySelector updateQuantity={ handleUpdateQuantity } toggled={ toggled } packUnit={ getProductPackUnit(product.ref, product.pack_unit) }  quantity={ item ? item.quantity : getProductPackUnit(product.ref, product.pack_unit) } />
                    }
                    { product.destock && <span className="badge rounded-pill text-bg-warning position-absolute top-0 end-0  mt-1 me-1">{ strings.destock }</span>}  
                </div>
                <div className='price d-flex text-primary p-1 justify-content-between'>
                        <div>
							{ product.ref }
					 		{ withStock ? strings.future_stock_article + futureStockValue : ''}
					 	</div>
                        <div>
                            { formatCurrency(product.regular_price) }
                        </div>
                </div>
                
            </div>
        </div>
    )
}



function ProductSingle(props){

    const { addItem, inCart, updateItem, getItem, removeItem } = useCart();
    let { productRef } = useParams()
    
    let [product,setProduct] = useState(null)
    let [categoryProducts, setCategoryProducts] = useState([])
    const [ orders, setOrders] = useState([])
    //{images:[notfound], ref:productRef,title:'Produit non trouvé',stock:0,regular_price:0,description:'Non trouvé', prices: []}
    
    const [mode, setMode] = useState("out-in");
    const [slideState, setSlideState] = useState(true);
    const slideRef = useRef();

    const navigate = useNavigate()
   

   
    // let products = localStorage.getItem('products') 
    // if(products){
    //     let allProducts = JSON.parse(products)
    //     let foundProduct = allProducts.find((p) => p.ref == productRef)
    //     if(foundProduct)
    //     product = foundProduct
    // } 

    
    const { getAll } = useIndexedDB('catalog');
    useEffect(() => {
        getAll().then(dataFromDB => {
            let foundProducts = dataFromDB.find(i => i.type == 'products')?.data ?? []            
            let foundProduct = foundProducts.find((p) => p.ref == productRef) 
            let foundOrders = dataFromDB.find(i => i.type === 'orders')?.data ?? [];
            setOrders(foundOrders)
            
            
            let prices = foundProduct.prices ?? [];

            for(var i=0; i< prices.length-1; i++)
            {
                prices[i].amount = prices[i+1].amount
                prices[i].min = prices[i].max+1
                prices[i].max = false
                
            }
            prices.pop()

            foundProduct.prices = prices;

            setProduct(foundProduct);

            if(sessionStorage.getItem('browse_mode') == 'list')
            {
                let categorySearch = JSON.parse(sessionStorage.getItem('products.search_category'))
                let productSearch =  sessionStorage.getItem('products.search_ref')
                let destockSearch =  sessionStorage.getItem('products.search_destock') === "true" ? true : false 

                const filteredProducts = filterProducts(foundProducts,{ 'ref': productSearch, 'category':categorySearch, 'destock':destockSearch })
                
                setCategoryProducts(filteredProducts)
            }
            
            else
            setCategoryProducts(foundProducts.filter(p => p.categories.ref === foundProduct.categories.ref))

            //console.log(foundProduct)
            
            if(foundProduct){
                if(inCart(foundProduct.ref))
                {
                    setQty( getItem(foundProduct.ref).quantity )
                    setDiscount( getItem(foundProduct.ref).discount )
                }
                else
                {                    
                    setQty(getProductPackUnit(foundProduct.ref, foundProduct.pack_unit))
                }
            }
        });

      

    }, [navigate]);

   
    

    const [qty, setQty ] = useState(1)
    const [ discount, setDiscount ] = useState()
    const price =  discount && isDiscounted(qty, discount) ? getDiscountPrice(product.regular_price, discount.amount) : product?.regular_price ?? 0
    const total = qty * price
    const [ min, setMin ] = useState(false)
    const [ max, setMax ] = useState(false)
    const [editedPackUnit, setEditedPackUnit] = useState(product?.pack_unit ?? 1)
    const [showPackUnitEdit, setShowPackUnitEdit] = useState(false)
    const [ initDefaultPack, setInitDefaultPack ] = useState(false)
  
    const swiperRef = useRef();

    if (product && product.pack_unit && editedPackUnit !== product.pack_unit && !initDefaultPack) {
        setEditedPackUnit(product.pack_unit);
        setInitDefaultPack(true);
    }
    

    const handleQtyChange = (qty) => {

         setQty(qty);
    }

    const getProductPackUnit = (id, defaultVal) => {
        return sessionStorage.getItem('products.productPackUnit.' + id) ? parseInt(sessionStorage.getItem('products.productPackUnit.' + id)) : defaultVal;
    }
    const toggleProductUnitEdit = () => {
        if (product && product.ref) {
            setShowPackUnitEdit(!showPackUnitEdit);
        }
    }
    const handleProductPackUnitOverride = (newPackUnit) => {
        setEditedPackUnit(newPackUnit)
        sessionStorage.setItem('products.productPackUnit.' + product.ref, newPackUnit)

        let newQty = parseInt(qty);
        if( ! newQty || newQty < 0) {
            newQty = 0;
        }
        const modulo = newQty % newPackUnit;
        newQty = newQty >= newPackUnit ? newQty - modulo : newPackUnit;
        if( (!min || newQty >= min) && (! max || newQty <= max) ) {
            setQty(newQty)
        }
    }

    const handleAddToCart = () => {        
        inCart(product.ref) ? updateItem(getItem(product.ref).id, { quantity: qty, price:price, regular_price: product.regular_price, discount: discount }) :  addItem({ id:product.ref, price: price, name: product.title,  image: product.images.length ? product.images : null, pack_unit : getProductPackUnit(product.ref, product.pack_unit), regular_price: product.regular_price, discount: discount}, qty)
        navigate(-1)
    }

    const handleRemoveFromCart = () => {

        if(inCart(product.ref))
        removeItem(getItem(product.ref).id)

        navigate(-1)
    }

    
   

    const handleDiscountChange = new_discount => {

        setDiscount(new_discount)
        setMin(new_discount?.min ?? false)
        setMax(new_discount?.max ?? false)

       

        if(new_discount?.min && qty < new_discount.min)
        setQty(new_discount.min)
        else
        if(new_discount?.max && qty > new_discount.max+1)
        setQty(new_discount.max)

        
    }

    
    let currentIndex = categoryProducts.findIndex(p => p.ref === product.ref)
    

    let nextProduct = categoryProducts.length > currentIndex+1 ? categoryProducts[currentIndex+1] : null
    let previousProduct = currentIndex > 0 ? categoryProducts[currentIndex-1] : null

  

    const gotoPreviousProduct = (e) => {
        e.preventDefault();
        setSlideState((state) => !state);
        if(previousProduct)
        navigate('/product/'+previousProduct.ref, { replace: true });
    }

    const gotoNextProduct = (e) => {  
        
        e.preventDefault();
        setSlideState((state) => !state);
        
        if(nextProduct)
        navigate("/product/"+nextProduct.ref, { replace: true });
    }

    const pageItems = [
        
        <li className='page-item pe-3' key='page-backward'>
            <a href="#" onClick={ gotoPreviousProduct } className={'page-link' + (! previousProduct ? ' opacity-50':'') }><i className='fa fa-backward'></i></a>
        </li>
    ]     
    

    pageItems.push(
        <li className='page-item ps-3' key='page-forward'>
            <a href="#" onClick={ gotoNextProduct } className={'page-link' + (! nextProduct ? ' opacity-50':'') }><i className='fa fa-forward'></i></a>
        </li>
    )

    const recursiveFindProductQty = (ref, status) => {
        let count = 0;
        let filteredOrders = orders.filter(o => o.status == status);
        if (status === 2) {
            filteredOrders = filteredOrders.filter(o => new Date(o.shipping_date * 1000) > new Date());
        }
        for (const key in filteredOrders) {
            if (Object.prototype.hasOwnProperty.call(filteredOrders, key)) {
                const order = filteredOrders[key];
                for (const k in order.items) {
                    if (Object.prototype.hasOwnProperty.call(order.items, k)) {
                        const orderItem = order.items[k];
                        if (orderItem.ref === ref) {
                            count += orderItem.quantity;
                        }
                    }
                }
            }
        }
        return count;
    }
    
    const handleFutureStocks = (ref, defaultStockValue) => {
        if (ref) {
            let productQtyFromOrdersConfirmed = recursiveFindProductQty(ref, 1);
            let productQtyFromOrdersSent = recursiveFindProductQty(ref, 2);

            let futureStock = defaultStockValue - (productQtyFromOrdersConfirmed + productQtyFromOrdersSent);
            return futureStock > 0 ? futureStock : 0;
        }
        return defaultStockValue;
    }

    return (
        
    <SwitchTransition mode={mode}>
        <CSSTransition
            key={slideState}
            nodeRef={slideRef}
            addEndListener={(done) => {
                slideRef.current.addEventListener("transitionend", done, false);
            }}
            classNames="fade"
        >
        <div ref={slideRef} className='product-single h-100 d-flex flex-column justify-content-between'>
            <Ariane>
                <h1 className='titre text-center flex-grow-1 fs-4'>{ product?.ref+" - "+product?.title }</h1>
            </Ariane>
            <div className='container-xxl'>
                <div className='m-0 row border-1 border border-white'>
                    <div className='col-lg-7 media p-0 gallery-wrapper position-relative'>
                        { product?.images.length > 0 ?
                        <Gallery images={ product.images } />
                        : ( product ? <img src={ notfound } className="img-contain img notfound" /> : '' ) }
                        { product?.destock && <div className='badge rounded-pill text-bg-warning position-absolute mt-3 top-0 me-3 end-0' style={ {zIndex:1000 } }>{ strings.destock }</div>}
                    </div>
                    <div className='col-lg-5 description p-0'>
                        
                        <Swiper 
                            ref={ swiperRef } 
                            effect="creative"
                            creativeEffect={{
                                prev: {
                                    shadow: true,
                                    translate: [0, 0, -100],
                                    opacity: 0
                                },
                                next: {
                                    translate: ["100%", 0, 0],
                                },
                            }}
                            modules={ [ EffectCreative ] } 
                            allowTouchMove={ false } 
                            className="h-100"
                            >
                            <SwiperSlide key="cart-item" className="d-flex flex-column justify-content-between">
                                <div className='px-5 pt-xl-4'>
                                    <TableRate prices={ product?.prices } active={ discount } handleChange={ handleDiscountChange }/>                                    
                                    <div className='container-fluid'>   
                                        
                                        <div className='row align-items-center justify-content-center'>
                                            <label className='col-xl-3 fs-4 mb-3 me-3'>{ strings.quantite }</label>
                                            <div className='col-xl-5 mb-3'>
                                                <QuantitySelector  locked="true" layout="horizontal" updateQuantity={ handleQtyChange } min={ min } max={ max } quantity={ qty } packUnit={ getProductPackUnit(product?.ref, product?.pack_unit) }/>
                                            </div>
                                        </div>
                                        <div className='row align-items-center justify-content-center border-bottom mb-4 pb-4'>
                                            <label className='col-xl-3 fs-4 me-3'></label>
                                            <div className='col-xl-6'>
                                                <legend className='pt-2'>{ strings.formatString(strings.min_commande,{ min: getProductPackUnit(product?.ref, product?.pack_unit ?? 1)}) } <i className='fa fa-edit' onClick={ toggleProductUnitEdit }></i></legend>
                                                { showPackUnitEdit 
                                                    ? <QuantitySelector locked="true" layout="horizontal" updateQuantity={ handleProductPackUnitOverride } min={ product.sub_packaging } max={ max } quantity={ editedPackUnit } packUnit={ product.sub_packaging }/>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                        <div className='prix fs-4'>
                                            <div className='small'>{ strings.prix_fiche } : { price != product?.regular_price ? <><s className='me-1 small'>{ formatCurrency(product?.regular_price) }</s><span className=''>{ formatCurrency(price) }</span></> : formatCurrency(price) }</div>
                                            <strong>{ strings.total } : </strong>{ formatCurrency(total) }
                                        </div>
                                        <div className='row comment my-4 d-none'>
                                            <div className='col-12 p-0'>
                                                <textarea rows="4" className='form-control' placeholder={ strings.commentaires} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={ handleAddToCart } type='button' className='btn btn-primary btn-lg fs-2 w-100 text-white'>
                                    { strings.ajouter_panier } <i className='fa fa-cart-plus'></i>
                                </button>
                            </SwiperSlide>
                            <SwiperSlide key="product-info" className="infos">
                                <div className='px-5 pt-4'>
                                    <h3 className='text-center fs-2 mb-4'>{ strings.fiche_article }</h3>
                                    <table className='table table-secondary table-bordered'>
                                        <tbody>                                        
                                            <tr>
                                                <td>{ strings.utilisation }</td>
                                                <td>{ product?.utilisation }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.matiere }</td>
                                                <td>{ product?.material }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.longueur }</td>
                                                <td>{ product?.length }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.largeur }</td>
                                                <td>{ product?.width }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.hauteur }</td>
                                                <td>{ product?.height }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.poids }</td>
                                                <td>{ product?.weight } kg</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.contenance }</td>
                                                <td>{ product?.capacity }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.stock }</td>
                                                <td>{ product?.stock }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.future_stock }</td>
                                                <td>{ handleFutureStocks(product?.ref, product?.stock) }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.colisage }</td>
                                                <td>{ product?.packaging }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.sous_colisage }</td>
                                                <td>{ product?.sub_packaging }</td>
                                            </tr>
                                            <tr>
                                                <td>{ strings.barcode }</td>
                                                <td>
                                                    <img src={'https://sales.faye-sas.fr/barcode.php?pid=' + product?.bar_code} alt='' className="img img-contain" />
                                                    { product?.bar_code }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </SwiperSlide>  
                        </Swiper>
                        
                        
                       
                    </div>
                </div>
            </div>
            <BottomNavigation>
                <div className='col-4 text-start'>
                    { inCart(product?.ref) &&
                    <button className='btn-lg btn btn-danger' onClick={ handleRemoveFromCart }><i className='fa fa-times me-3'></i>{ strings.delete}</button> }
                </div>
                <div className="col-4 d-flex align-items-center justify-content-center">
                    <ul className='pagination justify-content-center m-0 pagination me-3'>
                        { pageItems }
                    </ul>
                </div>
                <div className='col-4 text-end'>
                    <SwiperBtn click={ (index) => swiperRef.current?.swiper.slideTo(index) } index="1" active={ swiperRef.current?.swiper.activeIndex == 1 }  activeElement={ <span>Fiche technique <i className='fa fa-times ms-3'></i></span> }>
                        <span>
                            { strings.fiche_technique } <i className='far fa-file-pdf ms-3'></i>
                        </span>
                    </SwiperBtn>
                    <button className='btn-lg btn border-white text-white d-none'>Ajouter au favoris <i className='fa fa-heart ms-3'></i></button>
                </div>
            </BottomNavigation>
        </div>
        </CSSTransition>
    </SwitchTransition>
    )
}


export { ProductSingle, ProductList, ProductItem };

function SwiperBtn(props){

    const [active, setActive] = useState(props.active);
    const handleClick = () => {

        props.click(active ? 0 : props.index);
        setActive(!active);
    }
    
    return (
        <button className='btn-lg btn me-3 border-white text-white' onClick={ handleClick }>
            { active ? props.activeElement : props.children }
        </button>
    )
}

function Gallery(props){


    return (
        <Swiper slidesPerView="1" className="h-100 gallery" pagination={ { clickable: true } } modules={ [ Pagination ]}>
            { props.images.map( (image, index) => (
                <SwiperSlide key={ "gallery_" + index }>
                    <img src={ image } className="img img-contain" />
                </SwiperSlide>
            ))}
        </Swiper>
    )
}




function TableRate(props){
    
    
    const prices = props.prices?.filter(p => p.amount > 0)
    const setActive = (discount) => {

        if(active === discount)
        {            
            _setActive(null)
            props.handleChange(null)
        }        
        else
        {            
            _setActive(discount)
            props.handleChange(discount)
        } 
        
    }

    const [ active, _setActive ] = useState();
    
    useEffect( () => {

        _setActive(props.active)    
        if(props.active)
        swiperRef.current?.swiper.slideTo(1)
    }, [props.active])

    const sameDiscount = (a,b) => {
        return a.amount === b.amount && a.min === b.min && a.max === b.max
    }

    const swiperRef= useRef();


   
    return (

        <Swiper ref={ swiperRef } slidesPerView="1" className='swiper-price'>
            <SwiperSlide>
            </SwiperSlide>
            <SwiperSlide>
                <table className='table table-secondary table-bordered'>
                    <thead>
                        <tr>
                            { prices?.map((p,index) => (
                                <th key={index} role="button" onClick={ e => { setActive(p) }}>{ p.min }</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            { prices?.map((p,index) => (
                                <td key={index} className={ active && sameDiscount(active,p)  ? 'bg-primary text-white' : '' } role="button" onClick={ e => { setActive(p) }}>{ p.amount }%</td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </SwiperSlide>
        </Swiper>  
    )
}

TableRate.defaultProps = {
active: null,
}